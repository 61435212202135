import { Navigate, Outlet, useLocation } from "react-router-dom";
import { ROUTER_PATH } from "../../config";

export default function PrivateRoutes() {
  const auth = !!localStorage.getItem("cloudsols_user");
  
  const location = useLocation();
  return auth ? (
    <Outlet />
  ) : (
    <Navigate to={ROUTER_PATH.SIGN_IN} state={{ from: location }} replace />
  );
}
