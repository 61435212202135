import * as React from "react";
import { useState } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Box, Button, Typography } from "../../Components";
import IMAGES from "../../Assets/images";
import {
  getBloodOxygenAction,
  getBloodPressureAction,
  getGlucoseLevelAction,
  getPulseRateAction,
  getTemperatureAction,
  getWeightAction,
} from "../../Redux/actions/vitalMonitoringsAction";

const PATIENT_DETAILS = [
  {
    title: "Name",
    desc: "Jenny Wilson",
  },
  {
    title: "Date of birth",
    desc: "October 03, 1991",
  },
  {
    title: "Age",
    desc: "31 Years",
  },
  {
    title: "Occupation",
    desc: "Social Media Specialist",
  },
  {
    title: "Weight",
    desc: "200 lbs",
  },
  {
    title: "height",
    desc: "5.9 inches",
  },
  {
    title: "Mobile Contact",
    desc: "+62 811 09998263",
  },
  {
    title: "Work Contact",
    desc: "+62 811 09998263",
  },
  {
    title: "Post Code",
    desc: "253673",
  },
  {
    title: "Address",
    desc: "2972 Westheimer Rd. Santa Ana, Illinois 85486 ",
  },
];

const COLUMN_BOX_PROPS = {
  background: "#ffffff",
  borderRadius: "0.75rem",
  width: "100%",
  height: "100%",
  maxWidth: "202rem",
};

export default function VitalsMonitoring() {
  const [activeStep, setActiveStep] = useState(0);
  const [startClicked, setStartClicked] = useState(false);
  const [vital, setVital] = useState([]);
  const navigate = useNavigate();
  const vitals = useSelector((state) => state?.vitals);
  const dispatch = useDispatch();

  const settingVitalsValue = (key, value) => {
    //
    let data = {
      title: key,
      desc: value,
    };
    setVital([...vital, data]);
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (activeStep === 0) {
      dispatch(getPulseRateAction("Pulse Rate", settingVitalsValue));
    } else if (activeStep === 1) {
      dispatch(getTemperatureAction("Temperature", settingVitalsValue));
    } else if (activeStep === 2) {
      dispatch(getGlucoseLevelAction("GlucoseLevel", settingVitalsValue));
    } else if (activeStep === 3) {
      dispatch(getBloodOxygenAction("SPO2", settingVitalsValue));
    } else if (activeStep === 4) {
      dispatch(getWeightAction("weight", settingVitalsValue));
    }
  };

  const ShowValues = () => {
    return (
      <Row className="justify-content-start">
        {vital.map((item) => (
          <Col lg={4} sm={6}>
            <Typography as="p" color="#636967" className="mt-4">
              {item.title}
            </Typography>
            <Typography as="h6" color="#4A4F4D">
              {item.desc}
            </Typography>
          </Col>
        ))}
      </Row>
    );
  };

  const steps = [
    {
      icon: <img width={40} src={IMAGES.STEP_ICON_1} alt="icon" />,
      title: "Blood Pressure",
      subtitle: "Step 1",
      content: {
        heading: "Blood Pressure Monitoring",
        description:
          "Monitor and record your blood pressure measurements in mm Hg.",
      },
    },
    {
      icon: <img width={40} height={30} src={IMAGES.STEP_ICON_2} alt="icon" />,
      title: "Pulse",
      subtitle: "Step 2",
      content: {
        heading: "Pulse Measurement",
        description: "Measure and log your pulse rate in beats per minute.",
      },
    },
    {
      icon: <img width={20} height={44} src={IMAGES.STEP_ICON_3} alt="icon" />,
      title: "Temperature",
      subtitle: "Step 3",
      content: {
        heading: "Temperature Check",
        description:
          "Check and record your body temperature in degrees Celsius or Fahrenheit.",
      },
    },
    {
      icon: <img width={24} height={40} src={IMAGES.STEP_ICON_4} alt="icon" />,
      title: "Glucose Level",
      subtitle: "Step 4",
      content: {
        heading: "Glucose Level Monitoring",
        description:
          "Monitor and track your glucose levels in mmol/L or mg/dL.",
      },
    },
    {
      icon: <img width={40} height={40} src={IMAGES.STEP_ICON_5} alt="icon" />,
      title: "Respiratory",
      // title: "SPO2",
      subtitle: "Step 5",
      content: {
        heading: "Respiratory Measurement",
        // heading: "SPO2 Measurement",
        description:
          "Measure and log your blood oxygen saturation levels in percentage.",
      },
    },
    {
      icon: <img width={30} height={32} src={IMAGES.STEP_ICON_6} alt="icon" />,
      title: "Weight",
      subtitle: "Step 6",
      content: {
        heading: "Weight Tracking",
        description: "Track your weight and maintain a healthy lifestyle.",
      },
    },
    {
      icon: <img width={30} height={32} src={IMAGES.STEP_ICON_6} alt="icon" />,
      title: "Review",
      subtitle: "Step 7",
      content: {
        heading: "Review Your Vitals",
        description: "Review your vitals which you have added.",
        children: <ShowValues />,
      },
    },
  ];

  const handleStartClick = () => {
    dispatch(getBloodPressureAction("bloodPressure", settingVitalsValue));
    setStartClicked(true);
  };

  return (
    <Box
      width="100%"
      maxWidth="202rem"
      borderRadius="1.375rem"
      background="linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.70) 100%)"
      shadow="0px 0px 40px 20px rgba(0, 0, 0, 0.09)"
      backdropFilter="blur(15px)"
      className="p-5 m-auto"
    >
      <Typography as="h1" align="center">
        Vitals Monitoring
      </Typography>
      <Typography
        as="p"
        align="center"
        color="var(--logo-black-700, #4A4F4D)"
        className="py-1 px-2"
      >
        Please take some time to add vitals to help us better diagnose your
        problem.
      </Typography>

      <Row className="py-3">
        <Col>
          <Box {...COLUMN_BOX_PROPS} className="px-4 pt-3 pb-4">
            <Box className="w-100">
              <Stepper
                className="stepper-container"
                activeStep={activeStep}
                alternativeLabel
              >
                {steps?.map((step, index) => (
                  <Step className="mb-lg-0 mb-4" key={step.title}>
                    <StepLabel
                      icon={
                        <span
                          className={`custom-border ${
                            activeStep === index
                              ? "main-active"
                              : step.completed
                              ? "completed"
                              : ""
                          }`}
                        >
                          <span
                            className={`custom-step-icon ${
                              activeStep === index
                                ? "active"
                                : step.completed
                                ? "completed"
                                : ""
                            }`}
                          >
                            {step.icon}
                          </span>
                        </span>
                      }
                      classes={{
                        root: "custom-step-root",
                        labelIcon: "custom-step-icon",
                        active: "custom-step-active",
                        completed: "custom-step-completed",
                      }}
                    >
                      <Typography className="subTitle" variant="caption">
                        {step.subtitle}
                      </Typography>
                      <Typography className="title" variant="body2">
                        {step.title}
                      </Typography>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
              {activeStep === 7 &&
                navigate("/start-chat", { state: { vitals: vital } })}
            </Box>
          </Box>
        </Col>
      </Row>

      {/* Stepper content */}

      {startClicked ? (
        <React.Fragment>
          {/* Stepper content */}
          <Row className="py-3">
            <Col>
              <Box {...COLUMN_BOX_PROPS} className="px-4 pt-4 pb-5">
                <Box
                  className={`w-100 d-flex justify-content-center align-items-center py-4 ${
                    !steps[activeStep]?.content?.children &&
                    "justify-content-center"
                  }`}
                >
                  <div
                    className={`d-flex flex-column align-items-center justify-content-center `}
                  >
                    <span className="content-icon">
                      {steps[activeStep]?.icon}
                    </span>
                    {steps[activeStep]?.content?.children}
                    {!steps[activeStep]?.content.children && (
                      <>
                        <Typography
                          className="content-heading mt-4"
                          variant="h6"
                        >
                          {steps[activeStep]?.content.heading}
                        </Typography>
                        <Typography variant="p" className="content-stats">
                          {steps[activeStep]?.content.description}
                        </Typography>
                      </>
                    )}
                  </div>
                </Box>
              </Box>
            </Col>
          </Row>

          {/* Next button */}
          <div className="d-flex justify-content-end mt-3">
            <Button onClick={handleNext}>
              <Typography
                className="text-center"
                as="p"
                color="inherit"
                weight="600"
              >
                Next
              </Typography>
            </Button>
          </div>
        </React.Fragment>
      ) : (
        <Row className="py-3">
          <Col>
            <Box {...COLUMN_BOX_PROPS} className="px-4 pt-4 pb-5">
              <Box className="w-100 d-flex justify-content-center align-items-center py-4">
                <div className=" mt-3">
                  <button
                    className="autoplay-btn shadow-lg"
                    onClick={handleStartClick}
                  >
                    Start
                  </button>
                </div>
              </Box>
            </Box>
          </Col>
        </Row>
      )}
    </Box>
  );
}
