import { useNavigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Box, Button, CheckBox, Typography } from "../../Components";
import { IMAGES } from "../../Assets";
import { ROUTER_PATH } from "../../config";
import { toast } from "react-toastify";

const COLUMN_BOX_PROPS = {
  background: "#ffffff",
  borderRadius: "0.75rem",
  width: "100%",
  height: "100%",
  maxWidth: "202rem",
};
const VALIIDATION_SCHEMA = Yup.object().shape({
  option1: Yup.bool().required("Please the above consent!"),
  option2: Yup.bool().required("Please the above consent!"),
  option3: Yup.bool().required("Please the above consent!"),
});

const PATIENT_DETAILS = [
  {
    title: "Name",
    desc: "Jenny Wilson",
  },
  {
    title: "Date of birth",
    desc: "October 03, 1991",
  },
  {
    title: "Age",
    desc: "31 Years",
  },
  {
    title: "Occupation",
    desc: "Social Media Specialist",
  },
  {
    title: "Weight",
    desc: "200 lbs",
  },
  {
    title: "height",
    desc: "5.9 inches",
  },
  {
    title: "Mobile Contact",
    desc: "+62 811 09998263",
  },
  {
    title: "Work Contact",
    desc: "+62 811 09998263",
  },
  {
    title: "Post Code",
    desc: "253673",
  },
  {
    title: "Address",
    desc: "2972 Westheimer Rd. Santa Ana, Illinois 85486 ",
  },
];

export default function Home() {
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      option1: "",
      option2: "",
      option3: "",
    },
    validationSchema: VALIIDATION_SCHEMA,
    onSubmit: (values) => {
      debugger;
      if (!values.option1) {
        toast.error("Please Check Data Consent to continue!");
        return;
      }
      if (!values.option2) {
        toast.error("Please Check Communication Consent to continue!");
        return;
      }
      if (!values.option3) {
        toast.error("Please Check Diagnostic Consent to continue!");
        return;
      }
      navigate(ROUTER_PATH.VITALS_MONITORING);
    },
  });

  return (
      <Box
          width="100%"
          maxWidth="202rem"
          borderRadius="1.375rem"
          background="linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.70) 100%)"
          shadow="0px 0px 40px 20px rgba(0, 0, 0, 0.09)"
          backdropFilter="blur(15px)"
          className="p-5 m-auto"
      >
        <div className="mb-3">
            <Typography as="h1" align="center">
              Welcome to the AI Virtual Doctor
            </Typography>
            <Typography
                as="p"
                align="center"
                color="var(--logo-black-700, #4A4F4D)"
                className="py-1 px-2"
            >
              Welcome to Cloud Solutions - Your AI Doctor! Get personalized medical advice
              and diagnoses from the comfort of your screen.
            </Typography>
        </div>
        <Row className="py-3">
          <Col lg={6} className="my-3">
            <Box {...COLUMN_BOX_PROPS} className="px-4 pt-3 pb-4">
              <Typography as="h4" color="#2E3130" className="mb-2">
                Patient Details
              </Typography>
              <Box
                  width="100%"
                  maxWidth="85rem"
                  height="3px"
                  background="#E5E6E6"
                  border="1px solid #E5E6E6"
              ></Box>

              <Row className=" ">
                {PATIENT_DETAILS.map((item) => (
                    <Col lg={4} sm={6}>
                      <Typography as="label" color="#636967" className="mt-4">
                        {item.title}
                      </Typography>
                      <Typography as="p" color="#4A4F4D">
                        {item.desc}
                      </Typography>
                    </Col>
                ))}
              </Row>
            </Box>
          </Col>

          <Col lg={6} className="my-3">
            <Box {...COLUMN_BOX_PROPS} className="px-4 pt-3 pb-4">
              <Typography as="h4" color="#2E3130" className="mb-2">
                Appointment Details
              </Typography>
              <Box
                  width="100%"
                  maxWidth="85rem"
                  height="3px"
                  background="#E5E6E6"
                  border="1px solid #E5E6E6"
              ></Box>

              <Col sm={12} className="d-flex align-items-center mt-4">
                <div>
                  <img src={IMAGES.FOLLOW_UP_ICON} alt="" width="50px"/>
                </div>
                <div className="ms-3">
                  <Typography as="label" color="#636967">
                    Appt. Type
                  </Typography>
                  <Typography as="p" color="#4A4F4D">
                    Follow-Up
                  </Typography>
                </div>
              </Col>

              <Row className="mt-3">
                <Col md={6} xl={7} className="d-flex align-items-center py-2">
                  <div>
                    <img src={IMAGES.DATE_ICON} alt="" width="50px"/>
                  </div>
                  <div className="ms-3">
                    <Typography as="label" color="#636967">
                      Appt. Date
                    </Typography>
                    <Typography as="p" color="#4A4F4D">
                      June 11, 2023 on Wednesday
                    </Typography>
                  </div>
                </Col>
                <Col md={6} xl={5} className="d-flex align-items-center py-2">
                  <div>
                    <img src={IMAGES.TIME_ICON} alt="" width="50px"/>
                  </div>
                  <div className="ms-3">
                    <Typography as="label" color="#636967">
                      Appt. Time
                    </Typography>
                    <Typography as="p" color="#4A4F4D">
                      10:00 AM
                    </Typography>
                  </div>
                </Col>
              </Row>
            </Box>
          </Col>
        </Row>

        <Form onSubmit={formik.handleSubmit}>
          <Box {...COLUMN_BOX_PROPS} className="p-4">
            <h4>Please check the consents below</h4>
            <div className="mb-2">
              <div className="d-flex align-items-start mb-0">
                <CheckBox
                    // className="py-1"
                    name="option1"
                    id="checkbox1"
                    checked={formik.values.option1}
                    onChange={(e) => {
                      debugger;
                      formik.setFieldValue(
                          "option1",
                          e.target.checked === true ? true : ""
                      );
                    }}
                    // onChange={formik.handleChange}
                    // label="Data Consent:
                    // By using our services, you consent to the collection, storage, and processing of your personal data in accordance with our privacy policy for the purpose of providing you with our AI-driven healthcare services."
                />
                <label for="checkbox1">
                  <b>Data Consent: </b> By using our services, you consent to the
                  collection, storage, and processing of your personal data in
                  accordance with our privacy policy for the purpose of providing
                  you with our AI-driven healthcare services.
                </label>
              </div>
              {formik.touched.option1 && formik.errors.option1 && (
                  <small className="text-danger small mb-2">
                    {formik.errors.option1}
                  </small>
              )}
            </div>
            <div className="mb-2">
              <div className="d-flex align-items-start mb-0">
                <CheckBox
                    // className="py-1"
                    name="option2"
                    id="checkbox2"
                    checked={formik.values.option2}
                    onChange={(e) => {
                      debugger;
                      formik.setFieldValue(
                          "option2",
                          e.target.checked === true ? true : ""
                      );
                    }}
                    //   label="Communication Consent:
                    // You consent to receiving communications, including updates, notifications, and healthcare information, from Cloud Solutions via email or other preferred communication channels."
                />
                <label for="checkbox2">
                  <b>Communication Consent: </b>You consent to receiving
                  communications, including updates, notifications, and healthcare
                  information, from Cloud Solutions via email or other preferred
                  communication channels.
                </label>
              </div>
              {formik.touched.option2 && formik.errors.option2 && (
                  <small className="text-danger small mb-2">
                    {formik.errors.option2}
                  </small>
              )}
            </div>
            <div className="mb-2">
              <div className="d-flex align-items-start mb-0">
                <CheckBox
                    // className="py-1"
                    name="option3"
                    id="checkbox3"
                    checked={formik.values.option3}
                    // onChange={formik.handleChange}
                    onChange={(e) => {
                      debugger;
                      formik.setFieldValue(
                          "option3",
                          e.target.checked === true ? true : ""
                      );
                    }}
                    //   label="Diagnostic Consent:
                    // You understand that Cloud Solutions is an AI-based tool and not a substitute for professional medical advice. You consent to using our service with the understanding that the diagnoses and advice provided are based on AI algorithms and should be verified with a healthcare professional for accuracy and suitability for your specific situation."
                />
                <label for="checkbox3">
                  <b>Diagnostic Consent: </b> You understand that Cloud Solutions is an
                  AI-based tool and not a substitute for professional medical
                  advice. You consent to using our service with the understanding
                  that the diagnoses and advice provided are based on AI
                  algorithms and should be verified with a healthcare professional
                  for accuracy and suitability for your specific situation.
                </label>
              </div>
              {formik.touched.option3 && formik.errors.option3 && (
                  <small className="text-danger small mb-2">
                    {formik.errors.option3}
                  </small>
              )}
            </div>
          </Box>

          <div className="d-flex justify-content-end mt-3">
            <Button type="submit">
              <Typography
                  align="center"
                  fontSize="1.rem"
                  fontWeight="700"
                  color="inherit"
              >
                Proceed
              </Typography>
            </Button>
          </div>
        </Form>
      </Box>
  );
}
