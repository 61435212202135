import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import LayoutNavbar from "./Navbar/LayoutNavbar";
import bgVideo from "../Assets/video/cloudsols_bg.mp4";
import { ROUTER_PATH } from "../config";

export default function Layout() {
  const location = useLocation();
  return (
      <>
        <LayoutNavbar />
        <div
          className={
            // location.pathname === ROUTER_PATH.SIGN_IN
            //   ? "bg-white"
            // :s
            "main-container"
          }
          style={{
            background:
              location.pathname === ROUTER_PATH.SIGN_IN ? "white" : "none",
          }}
        >
          {/*{location.pathname !== ROUTER_PATH.PATIENT_DIAGNOSIS && (*/}
            <video src={bgVideo} autoPlay loop muted className="bg-video" />
          {/*)}*/}
          <Outlet />
        </div>
      </>
  );
}
