import PropTypes from "prop-types";

import { Box, Typography } from "../../../Components";
import { IMAGES } from "../../../Assets";
import { AiFillPlayCircle } from "react-icons/ai";

export default function ReplyTemplate({ ...props }) {
  return (
    <div className="d-flex align-items-center justify-content-start my-4">
      {!props.loader && (
        <>
          <img
            src={
              props?.isGender === "female"
                ? IMAGES.FEMALE_AVATAR
                : IMAGES.DOCTOR_AVATAR
            }
            alt=""
            width="60px" 
            height= {props.isGender === "female" ? "60px" : "60px"}
            className="mx-2"
            style={{ borderRadius: props.isGender === "female" ? "50%" : "0" }}
          />

          <Box
            background="#ffffff"
            borderRadius="0.75rem 0.75rem 0.75rem 0"
            className="px-3 py-2"
            width="fit-content"
            maxWidth="50rem"
          >
            <Typography as="p" className="m-2">
              {props.reply}
            </Typography>
          </Box>
          <Box
            width="1.5rem"
            height="1.5rem"
            background="#b91c1c"
            className="rounded-circle d-flex justify-content-center align-items-center mx-2 cursor-pointer"
            onClick={() => {
              props?.resetInActiveTimer();
              props?.getAudio(
                props.reply,
                props?.isGender === "female" ? 1 : 0
              );
            }}
            // onClick={() => props?.speakMessage(props.reply, props?.isGender)}
          >
            <AiFillPlayCircle color="#ffffff" size={20} />
          </Box>
        </>
      )}
    </div>
  );
}

ReplyTemplate.propTypes = {
  reply: PropTypes.string.isRequired,
};
