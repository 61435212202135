import React from "react";
import {Col, Container, Navbar, Row} from 'react-bootstrap';
import { IMAGES } from '../../Assets';
import {Typography} from "../../Components";

export default function LayoutNavbar() {
  return (
    <Navbar className="bg-none navbarWrapper py-0 m-0">
            <Row className="py-3 px-4 d-flex align-items-center w-100 m-0">
                <Col lg={1}>
                    <Navbar.Brand href="/">
                        <img
                            src={IMAGES.CLOUDSOL_LOGO}
                            width='60rem'
                            loading="lazy"
                            className="d-inline-block align-top"
                            alt="React Bootstrap logo"
                        />
                    </Navbar.Brand>
                </Col>
                <Col className="d-flex align-items-center justify-content-center gap-2">
                    <Typography align="center" fontWeight="bold" fontSize="1.25rem">
                        CLOUD
                    </Typography>
                    <Typography align="center" fontWeight="bold" fontSize="1.25rem" color="rgb(239 68 68 / 1)">
                        SOLUTIONS
                    </Typography>

                </Col>
            </Row>
    </Navbar>
  )
}
