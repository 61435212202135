import PropTypes from 'prop-types'

export default function Button({
  children,
  ...props
}) {
  const buttonStyles = {
    width: props.width,
    height: props.height,
    backgroundColor: props.backgroundColor,
    border: props.border,
    borderRadius: props.borderRadius,
    color: props.color,
  }
  return (
    <button {...props} style={buttonStyles} className={` ${props.className} buttonWrapper`} >
      {children}
    </button>
  )
}

Button.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  backgroundColor: PropTypes.string,
  border: PropTypes.string,
  borderRadius: PropTypes.string,
  onClick: PropTypes.func,
  hasIcon: PropTypes.bool,
  isIconLeft: PropTypes.bool,
  isIconRight: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
}

Button.defaultProps = {
  width:'10rem',
  height:"3rem",
  backgroundColor :  "hsl(0 0% 100%)" ,
  border: '1px solid rgb(239 68 68 / 1)',
  borderRadius: '14px',
  color:"rgb(239 68 68 / 1)",
  onClick: ()=>{},
  hasIcon: false,
  isIconLeft: false,
  isIconRight: false,
  className: '',
}