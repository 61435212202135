import PropTypes from "prop-types";

import { Box, Typography } from "../../../Components";
import { IMAGES } from "../../../Assets";

export default function MessageTemplate({ ...props }) {
  return (
    <div className="d-flex justify-content-end my-4">
      <Box
        background="#b91c1c"
        borderRadius="0.75rem 0.75rem 0 0.75rem"
        width="fit-content"
        maxWidth="50rem"
        className="px-3 py-2"
      >
        <Typography as="p" className="m-2" color="#ffffff">
          {props.message}
        </Typography>
      </Box>

      <img
        // src={IMAGES.USER_AVATAR}
        src={`https://ui-avatars.com/api/?name=${`${"Abid"} ${"Ali"}`}&background=fff&color=b91c1c`}
        alt=""
        width="60px"
        className="ms-2 rounded-5"
      />
    </div>
  );
}

MessageTemplate.propTypes = {
  message: PropTypes.string.isRequired,
};
